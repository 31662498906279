import React, { Component } from 'react'
import styled from 'styled-components';
import video from './../images/Video.mp4'
import { device } from './base';

export default function Video() {
  return (
  <ImageContainer>
  <video controls >
      <source src={video} type="video/mp4"/>
     </video>
  </ImageContainer>
    
    );
  }   


  const ImageContainer = styled.section`

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  background: #fffff;

  video {
  width: 100%;
  height: 700px;
  object-fit: scale-down;
}
@media screen and (${device.sm}) {
  video {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}
 img:hover {
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

`