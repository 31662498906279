import React from 'react';
import { atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

const CalendarContainer = () => {
  React.useEffect(atcb_init, []);
  return (
    <div className="atcb">
      { '{' }
        "name":"Lek ❤️ Chikku",
        "description":"Wedding invite for Haritha and Nithin",
        "startDate":"2023-01-31",
        "endDate":"2023-01-31",
        "startTime":"10:00",
        "endTime":"12:00",
        "location":"Golden Palace Auditorium, 7H4P+FFX, Chengannur Kollakadavu Road, Kerala 690509, India",
        "label":"Add to Calendar",
        "options":[
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "Outlook.com",
          "Yahoo"
        ],
        "timeZone":"Asia/Kolkata",
        "iCalFileName":"Reminder-Event"
      { '}' }
    </div>
  );
}

export default CalendarContainer;