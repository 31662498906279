import React from 'react'
import styled from 'styled-components'
import { Container, device, Divider, IFrame } from './base'

export default function Footer() {
  return (
    <FooterContainer>
      <Container className="flex-box">
        <div className="left">
          <p>
            Wedding day:{' '}
            <b>The groom's party starts at 9am from Nithin's residence</b>
          </p>
        
          <Divider space={'1rem'} />
          <IFrame
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1027.9829990483265!2d76.56011120769574!3d9.334226082794657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0623d27fc1543f%3A0x877f0adf05924931!2zOcKwMjAnMDQuMCJOIDc2wrAzMyczNy4wIkU!5e0!3m2!1sen!2sin!4v1667271926343!5m2!1sen!2sin"
            height="200"
            width="100%"
            allowfullscreen={false}
            loading="lazy"
            title="Groom location"
          />
        </div>
        <div className="right">
          Ph: <b>+91 9567320219</b>
          <br />
          Ph: <b>+91 9947730609</b>
          <br />
          Whatsapp: <b>+91 9567320219</b>
          <br />
          Email: <b>nithinprasad549@yahoo.com</b>
        </div>
      </Container>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  background-color: #2d112b;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding: 2.5rem 0rem;
  line-height: 2;
  font-size: 0.85em;

  p {
    margin: 0;
  }

  .left {
    text-align: left;
    @media screen and (${device.sm}) {
      text-align: center;
    }
  }

  .right {
    text-align: right;
    @media screen and (${device.sm}) {
      text-align: center;
    }
  }

  .flex-box {
    display: flex;
    justify-content: space-between;

    @media screen and (${device.sm}) {
      flex-direction: column;
      gap: 2rem;
      text-align: center;
    }
  }

  iframe {
    
  }
`
