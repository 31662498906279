import React from 'react'
import {
  Container,
  Heading,
  SideBySide,
  LeftSection,
  RightSection,
  SubHeading,
  Text,
  SecText,
  IFrame,
  Divider,
  device,

} from './base'

import styled from 'styled-components'
import ReceptionCalendarContainer from './ReceptionCalenderContainer'

export default function Reception() {
  return (
    
    <ReceptionContainer>
      <Divider space={'2rem'} />
      <Heading>Reception Day</Heading>
      <Divider space={'1rem'} />
      <Container>
      <SideBySide>
        <LeftSection>
          <IFrame
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1027.9829990483265!2d76.56011120769574!3d9.334226082794657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0623d27fc1543f%3A0x877f0adf05924931!2zOcKwMjAnMDQuMCJOIDc2wrAzMyczNy4wIkU!5e0!3m2!1sen!2sin!4v1667271926343!5m2!1sen!2sin"
            height="350"
            width="100%"
            allowfullscreen={false}
            loading="lazy"
            title="reception location"
          />
        </LeftSection>
        <RightSection>
          <Text>
            The newlyweds will travel to the groom's residence on the same day
            of the wedding.Reception at the groom's side is arranged on the same day.
          </Text>
          <Divider space={'1.5rem'} />
          <SecText >Date: 31 Jan 2023</SecText>
          <SecText>Time: 18.30 - 22:30</SecText>
          <SecText>
            Place: Padinjattedathu House, Pandanad West PO , Chengannur.
          </SecText>
          <SecText>Dresscode: Anything you are comfortable with</SecText>
         
        </RightSection>
      </SideBySide>
      </Container>
      <ReceptionCalendarContainer/>
      <Divider space={'1rem'} />
      <Text>
        New to Chengannur?&nbsp;
        <a
          href="https://www.tripadvisor.in/AttractionsNear-g2235992-d4142700-Chengannur_Station-Thiruvalla_Pathanamthitta_District_Kerala.html"
          target="_blank"
          rel="noreferrer"
        >
          Checkout the local attractions you can explore
        </a>
      </Text>

      <Divider space={'4rem'} />
    </ReceptionContainer>

  )
}

const ReceptionContainer = styled.div`
  min-height: 100vh;
  background-color: #f9ded8;
  display: flex;
  flex-direction: column;
  color: white;
  h1 {
    margin: 0;
    color: black;
  }

  @media screen and (${device.sm}) {
    min-height: 1170px;
    margin-top: -1px;
    font-size: 14px;
  }
`
