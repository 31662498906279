import React from 'react'
import Countdown from 'react-countdown'
import {RoundBox,BoxNumber,BoxWord } from './base'

export default function CountDown({ date }) {
  return <Countdown date={date} renderer={renderer} />
}

// Renderer callback with condition
const renderer = ({ days, hours, minutes, completed }) => {
  if (completed) {
    // Render a completed state
    return null
  } else {
    // Render a countdown
    return <span>
       <RoundBox>
                <BoxWord>{days}</BoxWord>
                <BoxNumber>days</BoxNumber>             
             </RoundBox>
             <RoundBox>
                <BoxWord>{hours}</BoxWord>
                <BoxNumber>hours</BoxNumber>             
             </RoundBox>
             <RoundBox>
                <BoxWord>{minutes}</BoxWord>
                <BoxNumber>minutes</BoxNumber>             
             </RoundBox>
    </span>
  }
}
