import React from 'react'
import Header from './components/Header'
import Banner from './components/Banner'
import Wedding from './components/Wedding'
import Reception from './components/Reception'
import Footer from './components/Footer'
import Introduction from './components/Introduction'
import GalleryContainer from './components/GalleryContainer'
import './App.css'
import HowToReach from './components/HowToReach'
import GalleryContainerScroll from './components/GalleryContainerScroll'
import BannerNew from './components/BannerNew'
import GalleryContainerScrollSaveDate from './components/Video'
import Video from './components/Video'


function App() {
  return (
    <div className="App">
      <Header />
      <BannerNew/>
      <Video/>
      <Introduction />
      <Wedding />
      <Reception/>
      <HowToReach/>
      <GalleryContainerScroll/>
      <Footer />
    </div>
  )
}

export default App
