import React from 'react'
import styled from 'styled-components'
import AddToCalendar from 'react-add-to-calendar';
import {
  Container,
  Heading,
  SideBySide,
  LeftSection,
  RightSection,
  SubHeading,
  Text,
  SecText,
  IFrame,
  Divider,
  ThreeSections,
  SectionOne,
  SectionTwo,
  SectionThree,
  device,
  WhiteText,
} from './base'
import CalendarContainer from './CalenderContainer';
import Button from 'react-bootstrap/Button';


export default function HowToReach() {
  const BookingEvent = {
    event: {
      "@context": "https://schema.org",
      "@type": "Event",
      name: "Add the title of your event",
      startDate: "2022-04-21",
      endDate: "2022-04-22"
    },
    options: [
      "Google"
    ]
  }

  let icon = { 'calendar-plus-o': 'left' };


  return (
    <WeddingContainer>
      <Container>
        <Heading>How to Reach</Heading>
        <Divider space={'1rem'} />
        <ThreeSections>
          <SectionOne>
            <Container>
              <img src='https://img.icons8.com/pastel-glyph/512/non-stop-flight.png' height={50} />
              <SubHeading>
                The Nearest airport will be Kochi(COK) or Trivandrum (TRV) , It will be a 3 Hour ride to the Nearest airport
              </SubHeading>
              <Divider space={'1rem'} />
              {ButtonContainer('https://www.google.com/travel/flights/search?tfs=CBwQAhooagwIAxIIL20vMDljMTcSCjIwMjMtMDEtMjdyDAgDEggvbS8wZmwyc3ABggELCP___________wFAAUgBmAEC','Flights')}
            </Container>
          </SectionOne>
          <SectionTwo>
            <Container>
              <img src='https://img.icons8.com/ios-filled/512/people-in-car.png' height={50} />
              <SubHeading>
              If you have time for a drive, there’s really nothing better than ending a road trip through Aleppy. Aleppy is conveniently located at the NH .
              </SubHeading>
              <Divider space={'1rem'} />
              {ButtonContainer('https://www.google.com/maps/dir//Golden+Palace+Auditorium,+Chengannur+Kollakadavu+Road,+Kerala/@9.2562236,76.5161897,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3b0618fa61e7541d:0x6e61e22f66a934d5!2m2!1d76.5862528!2d9.2562312','Routes')}
            </Container>
          </SectionTwo>
          <SectionThree>
            <Container>
              <img src='https://img.icons8.com/ios-filled/512/high-speed-train.png' height={50} />
              <SubHeading>
                One of the best option , You can catch any train through Chengannur (CNGR) or Harippad(HAD) , CNGR will be convinient option and located 10 KM from the destination 
              </SubHeading>
              <Divider space={'1rem'} />
              {ButtonContainer('https://www.railyatri.in/trains-between-stations-v2?from_code=SBC&from_name=BANGALORE%20CITY%20JN%20&journey_date=27-01-2023&src=&to_code=CNGR&to_name=CHENGANNUR%20&user_id=&user_token=&utm_source=','Train')}
            </Container>
          </SectionThree>
        </ThreeSections>

      </Container>
      <Divider space={'4rem'} />
      <Container>
      </Container>
    </WeddingContainer>

  )
}

const WeddingContainer = styled.section`
  padding-top: 3rem;
  background: #50b796;
  h1 {
    margin-top: 0;
  }
`

const ButtonContainer = (url,title) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
    > 
    <Button variant="primary"> Find {title}</Button>
    </a>

  )
}

