import styled from 'styled-components'

const size = {
  xs: '320px',
  sm: '450px',
  md: '768px',
  lg: '1200px',
}
export const device = {
  xs: `max-width: ${size.xs}`,
  md: `max-width: ${size.md}`,
  sm: `max-width: ${size.sm}`,
  lg: `max-width: ${size.lg}`,
}

export const Container = styled.section`
  width: 90%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;

  @media only screen and (${device.sm}) {
    font-size: 14px;
  }
`

export const Heading = styled.h1`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2.5rem;
  color: #444;
`

export const SideBySide = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media only screen and (${device.sm}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`

export const LeftSection = styled.div`
  text-align: left;
`

export const RightSection = styled.div`
  text-align: left;
`

export const SubHeading = styled.h3`
  margin: 0;
  line-height: 1.25;
  font-family: 'Josefin Sans', sans-serif;
  color: #2d112b;
  font-weight: 500;
`

export const Text = styled.p`
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5rem;
  color: #444;
  margin: 0;
`

export const WhiteText = styled.p`
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5rem;
  color: white;
  margin: 0;
`

export const IFrame = styled.iframe`
  border: 0;
`

export const SecText = styled.p`
  font-family: 'Nunito', sans-serif;
  color: #777;
  font-size: 1rem;

  &.no-margin {
    margin: 0;
    margin-top: -0.75rem;
  }
`

export const WhiteSecText = styled.p`
  font-family: 'Nunito', sans-serif;
  color: white;
  font-size: 1rem;

  &.no-margin {
    margin: 0;
    margin-top: -0.75rem;
  }
`

export const Divider = styled.div`
  height: ${(props) => props.space ?? 0};
`

export const ThreeSections = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  @media only screen and (${device.sm}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`

export const SectionOne = styled.div`
  text-align: left;
`

export const SectionTwo = styled.div`
  text-align: left;
`

export const SectionThree = styled.div`
  text-align: left;
`
export const RoundBox = styled.div`
    display: inline-block;
    width: 100px;
    height: 100px;
    background: rgba(241, 78, 149, 0.8);
    margin: 0 4px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    animation: pulse 1s ease infinite;
  `

  export const BoxWord = styled.div`
    display: block;
    color: white;
    padding-top: 15px;
    `

    export const BoxNumber = styled.div`
    display: block;
    color: white;
    font-size: 1rem;
    `