import React from 'react'
import { Divider, Heading, device } from './base'
import SpeachBubble from './SpeachBubble'
import image from '../images/cari.png'
import coupleImage from '../images/c4-removebg-preview.png'
import styled from 'styled-components'

export default function Introduction() {
  return (
    <IntroductionContainer>
      <Divider space={'3rem'} />
      <Heading>Who are we?</Heading>
      <ImageContainer>
        <BubbleContainer>
          <SpeachBubble>
            Haritha  H Kumar
          
            <br />
            Cheriyanad, India
            <br />
            <br />
            <span className="light-label">A.</span> Multi-talented <br />
            <span className="light-label">B.</span>Sleeping Beauty <br />
            <span className="light-label">C.</span> Medical Geek
          </SpeachBubble>
          <SpeachBubble align="right">
            Nithin Prasad
            <br />
            Chengannur, India
            <br />
            <br />
            <span className="light-label">A.</span> Has a good heart <br />
            <span className="light-label">B.</span> Sports enthusiast <br />
            <span className="light-label">C.</span> Techie
          </SpeachBubble>
        </BubbleContainer>
      </ImageContainer>
    </IntroductionContainer>
  )
}

const IntroductionContainer = styled.div`
  min-height: 100vh;
  background-color: #2d112b;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0;
    color: white;
  }

  @media screen and (${device.sm}) {
    min-height: 1170px;
    margin-top: -1px;
    font-size: 14px;
  }
`

const ImageContainer = styled.div`
  background-image: url(${coupleImage});
  width: 90%;
  max-width: 1200px;
  flex: 1;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 50% auto;
  min-height: 900px;
  margin: auto;
  display: flex;
  justify-content: flex-end;

  @media screen and (${device.sm}) {
    background-size: 100% auto;
    background-position: bottom right;
    width: 100%;
    padding: 0rem 0.5rem;
    display: block;
    margin-top: 5rem;
    font-size: 14px;
    box-sizing: border-box;
  }
`

const BubbleContainer = styled.div`
  margin-top: 4rem;
  width: 50%;

  .light-label {
    color: #2d112b;
    opacity: 0.3;
  }

  @media screen and (${device.sm}) {
    width: 95%;
    margin: auto;
  }

  > div {
    margin-bottom: 8rem;
    width: 85%;

    @media screen and (${device.sm}) {
      margin-bottom: 6rem;
    }
  }
`
