import React from 'react'
import styled from 'styled-components'
import { Container, device } from './base'
import Birds from './Birds'
import CountDown from './CountDown'
import AddToCalendar from 'react-add-to-calendar';
import CalendarContainer from './CalenderContainer'
import images from '../images/topo.svg'

export default function Banner() {
  return (
    <BannerContainer>
      <BirdsContainer>
        <Birds />
      </BirdsContainer>

      <TimerContainer>
        <h1>
          Nithin <br /> &amp; <br />
          Haritha
        </h1>
        <span>Starting a new journey in</span>
        <CountDownContainer>
          <CountDown
            date={new Date('Tue Jan 31 2023 11:30:00 GMT+0530 (IST)')}
          />
        </CountDownContainer>
      </TimerContainer>
    </BannerContainer>
  )
}

const BannerContainer = styled(Container)`
  height: 100vh;
  background-image: url(${images});
  background-color: #f9ad81;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;

  @media only screen and (${device.sm}) {
    background-size: auto 100%;
  }
`

const TimerContainer = styled.div`
 
  color: white;
  max-width: 500px;
  width: 90%;
  margin: auto;
  padding: 1rem;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 3px;

  @media screen and (min-width: 1000px) {
    padding: 2rem;
    max-width: 700px;
  }

  h1 {
    font-size: 5em;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    line-height: 1.3;
    margin: 0;
  }

  > span {
    font-size: 1rem;
   
  }
`

const BirdsContainer = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  overflow: hidden;
`

const CountDownContainer = styled.div`
  font-size: 2rem;
  margin: 1rem 0rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  color: #fff;
`
const BookingEvent = {
  event: {
    "@context": "https://schema.org",
    "@type": "Event",
    name: "Add the title of your event",
    startDate: "2022-04-21",
    endDate: "2022-04-22"
  },
  options: [
    "Google"
  ]
}

let icon = { 'calendar-plus-o': 'left' };
