import React from 'react'
import styled from 'styled-components'
import AddToCalendar from 'react-add-to-calendar';
import {
  Container,
  Heading,
  SideBySide,
  LeftSection,
  RightSection,
  SubHeading,
  Text,
  SecText,
  IFrame,
  Divider,
} from './base'
import CalendarContainer from './CalenderContainer';



export default function Wedding() {
  const BookingEvent = {
    event: {
      "@context": "https://schema.org",
      "@type": "Event",
      name: "Add the title of your event",
      startDate: "2022-04-21",
      endDate: "2022-04-22"
    },
    options: [
      "Google"
    ]
  }

  let icon = { 'calendar-plus-o': 'left' };


  return (
    <WeddingContainer>
      <Container>
        <Heading>Wedding Day</Heading>
        <Divider space={'1rem'} />
        <SideBySide>
          <LeftSection>
            <Text>Nithin will tie the wedding knot on</Text>
            <Divider space={'1.5rem'} />
            <SubHeading>Date: 31 Jan 2023</SubHeading>
            <SubHeading>Time: 10:30 - 11:00</SubHeading>
            <SubHeading>
              Place: Golden Palace, Kollakadavu.
            </SubHeading>
            <Divider space={'1rem'} />
            <CalendarContainer></CalendarContainer>
          </LeftSection>

          <RightSection>
            <IFrame
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3937.866422949773!2d76.5840431133483!3d9.256230393363438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0618fa61e7541d%3A0x6e61e22f66a934d5!2sGolden%20Palace%20Auditorium!5e0!3m2!1sen!2sin!4v1666673028524!5m2!1sen!2sin"
              height="350"
              width="100%"
              allowfullscreen={false}
              loading="lazy"
              title="wedding location"
            />
          </RightSection>
        </SideBySide>
      </Container>
      <Divider space={'4rem'} />
      <Container>

      </Container>
      <Container>
        <Divider space={'2rem'} />

        <Text>
          New to Alappuzha?{' '}
          <a
            href="https://www.tripadvisor.in/Attractions-g608471-Activities-zft11309-Alappuzha_Alappuzha_District_Kerala.html"
            target="_blank"
            rel="noreferrer"
          >
            Checkout the local attractions you can explore
          </a>
        </Text>
        <Divider space={'4rem'} />
      </Container>
    </WeddingContainer>


  )
}

const MoreDetails = styled(Container)`
  background-color: #2d112b;
  padding: 2.5rem 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 2px #f3d1d1;

  > p {
    max-width: 700px;
    width: 90%;
    color: white;
    margin: auto;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
  }
`

const WeddingContainer = styled.section`
  padding-top: 3rem;
  background: #e4f1e0;
  h1 {
    margin-top: 0;
  }
`
