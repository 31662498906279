import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import styled from 'styled-components';
import { Container, device, Divider, Heading, LeftSection, RightSection, SideBySide } from './base'
import { photos } from "./photos";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function GalleryContainer() {

  return (

    <GalleryContainerDiv>
      <Container>
        <Divider space={'2rem'} />
        <Heading>Our Memories</Heading>
        <ImageContainer space={'1rem'} >
          {getPhotos().map((item, index) =>
            <div>
              <img key={item.src} src={item.src} />
            </div>)}         
        </ImageContainer>
      </Container>
    </GalleryContainerDiv>
  )
}


const GalleryContainerDiv = styled(Container)`
  background: none;
  padding: 1rem;
  margin: 1rem;
`

function getPhotos() {
  const directory = require.context("../../public/Photos", false, /\.(png|jpe?g|svg)$/);
  let imagePaths = getImagePaths(directory);
  return imagePaths;
}



function getImagePaths(directory) {
  let images = [];
  directory.keys().map((item, index) => images.push(populateSrc(process.env.PUBLIC_URL + "/Photos", item)));
  return images;
}

function populateSrc(directory, path) {

  return {
    src: process.env.PUBLIC_URL + "/Photos" + path.replace("./", "/"),
    width: 3,
    height: 4
  }
}

const ImageContainer = styled.section`

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  background: #fffff;

 img {
  width: 100%;
  display: block;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transition: all 100ms ease-out;
}
 img:hover {
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

`

