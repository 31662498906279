import React from 'react';
import { atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

const ReceptionCalendarContainer = () => {
  React.useEffect(atcb_init, []);
  return (
    <div className="atcb">
      { '{' }
        "name":"Reception of Lek ❤️ Chikku",
        "description":"Reception invite for Haritha and Nithin",
        "startDate":"2023-01-31",
        "endDate":"2023-01-31",
        "startTime":"18:30",
        "endTime":"23:00",
        "location":"RKV LPS(Radha Krishna Vilasam Lower Primary School), 8HJ7+38F, Chengannur Parumala Mannar Rd, Pandanad, Kerala 689506, India",
        "label":"Add to Calendar",
        "options":[
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "Outlook.com",
          "Yahoo"
        ],
        "timeZone":"Asia/Kolkata",
        "iCalFileName":"Reminder-Event"
      { '}' }
    </div>
  );
}

export default ReceptionCalendarContainer;