import React from 'react'
import styled from 'styled-components'
import { Container, device, SectionOne, SectionThree, SectionTwo, ThreeSections } from './base'
import Birds from './Birds'
import CountDown from './CountDown'
import AddToCalendar from 'react-add-to-calendar';
import CalendarContainer from './CalenderContainer'
import images from '../images/topo.svg'
import coupleImage from '../images/cari.png'

export default function BannerNew() {
  return (
    <BannerContainer>
      <BirdsContainer>
        <Birds />
      </BirdsContainer>

    
      <ThreeSections>
        <SectionOne>
          <TimerContainer>
            <h1>
              Nithin <br /> &amp; <br />
              Haritha
            </h1>

          </TimerContainer>
        </SectionOne>
        <SectionTwo>
          <ImageContainer></ImageContainer>
        </SectionTwo>
        <SectionThree>
          <TimerContainer>
            <h2>Starting a new journey in </h2>

            <CountDownContainer>
              <CountDown
                date={new Date('Tue Jan 31 2023 11:30:00 GMT+0530 (IST)')}
              />
            </CountDownContainer>
          </TimerContainer>
        </SectionThree>
      </ThreeSections>
    </BannerContainer>
  )
}

const BannerContainer = styled(Container)`
    text-align: center;
    height: 100%;
    background-image: url(${images});
    background-color: #f9ad81;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;

    @media only screen and (${device.sm}) {
      background-size: auto 100%;
      min-height: 1170;
  }
        `

const ImageContainer = styled(Container)`
    height: 100vh;
    background-image: url(${coupleImage});
    background-color: #f9ad81;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;

    @media only screen and (${device.sm}) {
      background-size: 100% auto;
      height: 60vh;
  }
`

const TimerContainer = styled.div`
  color: white;
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 1rem;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  @media screen and (min-width: 1000px) {
    padding: 2rem;
    max-width: 700px;
  }

  h1 {
  font-size: 4em;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
  text-align: center;
  padding-top: 5rem;
  }
  h2 {
    font-size: 3em;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    line-height: 1.3;
    margin: 0;
    text-align: center;
    padding: 3rem 1rem;
    }

  > span {
      font - size: 1rem;
  }
        `

const BirdsContainer = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  overflow: hidden;
  `

const CountDownContainer = styled.div`
  font-size: 2rem;
  margin: 1rem 0rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  color: #fff;
  `


