import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import styled from 'styled-components';
import { Container, device, Divider, Heading, LeftSection, RightSection, SideBySide } from './base'
import { photos } from "./photos";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';

export default function GalleryContainerScroll() {

  return (

    <WeddingContainer>
      <Heading>Our Memories</Heading>
      <Divider space={'2rem'} />
      <GalleryContainerDiv>
      <Carousel>
        {getPhotos().map((item, index) =>
          <Carousel.Item>
            <ImageContainer>
            <img
              className="rounded mx-auto d-block"
              src={item.src}
              alt={index}
              width={200}
            />
             </ImageContainer>
          </Carousel.Item>
        )}
       
      </Carousel>
      </GalleryContainerDiv>
       <Divider space={'2rem'} />
    </WeddingContainer>

  )
}



const WeddingContainer = styled.section`

  padding-top: 3rem;
  background-color: white;
  padding-bottom: -30rem;
  h1 {
    margin: 0;
    color: black;
  }
  @media screen and (${device.sm}) {
    margin-top: -1px;
    font-size: 14px;
  }
  margin: 0;
`

const GalleryContainerDiv = styled(Container)`
  background-image: url(https://s3.amazonaws.com/ameliaandsteveassets/plants.jpg); */
  -webkit-background-size: cover;
  background-size: cover;
  width: 50;
  height: 50;
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  
  }
  
  ::-webkit-scrollbar-button {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  
  }
`

function getPhotos() {
  const directory = require.context("../../public/Photos", false, /\.(png|jpe?g|svg)$/);
  let imagePaths = getImagePaths(directory);
  return imagePaths;
}



function getImagePaths(directory) {
  let images = [];
  directory.keys().map((item, index) => images.push(populateSrc(process.env.PUBLIC_URL + "/Photos", item)));
  return images;
}

function populateSrc(directory, path) {

  return {
    src: process.env.PUBLIC_URL + "/Photos" + path.replace("./", "/"),
    width: 3,
    height: 4
  }
}

const ImageContainer = styled.section`

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  background: #fffff;

 img {
  width: 100%;
  height: 700px;
  object-fit: scale-down;
}
@media screen and (${device.sm}) {
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}
 img:hover {
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

`